<template>
  <div>

    <ContentHeader :class="layout.desktop + ' mb-home-content-header'"
                   :title="$t('global.safeometer_takeTest')"
                   :msg="$t('test_page.safeometer_test_generalQuestion')"
                   :fsize="`35px`"
                   :line-height="`45px`"
    />

    <ContentHeader :class="layout.mobile + ' mb-home-content-header'"
                   :title="$t('global.safeometer_takeTest')"
                   :msg="$t('test_page.safeometer_test_generalQuestion')"
                   :fsize="`28px`"
                   :line-height="`36px`"
    />

    <Progress
        :progress="currentProgress"
        :current-points="currentPoints"
        :percentage-labels="[20,40,60,80]"
        :marker-background="markerBackground"
        :marker-label="markerLabel"
    ></Progress>

    <div class="mb-test-question">
      <Questions
          @updated="updated"
          @updateMarkerBackground="updateMarkerBackground"
          @endTestListener="loadResult"
      ></Questions>
    </div>

  </div>
</template>

<script>
import ContentHeader from "@/components/ContentHeader";
import Questions from "@/components/Questions";
import Progress from "@/components/Progress";

export default {
  name: "Test",
  components: {
    ContentHeader,
    Questions,
    Progress
  },
  data() {
    return {
      width: '0%',
      currentProgress: '0',
      currentPoints: 0,
      markerBackground: "#F0B000",
      higherQuestionType: -1,
      markerLabel: "",
      layout: this.$responsiveLayout
    }
  },
  computed: {
    computedWidth: function () {
      return this.width;
    }
  },
  methods:{
    updated(progress, currentPoints){
      this.width = `${ progress }%`;
      this.currentProgress = progress;
      this.currentPoints = currentPoints;
    },
    updateMarkerBackground(type){
      let qTypeValue = this.getQuestionTypeValue(type);
      this.higherQuestionType = Math.max(qTypeValue, this.higherQuestionType);
      switch(this.higherQuestionType){
        case 0:
          this.markerBackground = "#F0B000";
          this.markerLabel = this.$t('test_page.indicators.safeometer_test_indicator_warning').toUpperCase();
          break;
        case 1:
          this.markerBackground = "#FF8000";
          this.markerLabel = this.$t('test_page.indicators.safeometer_test_indicator_react').toUpperCase();
          break;
        case 2:
          this.markerBackground = "#E50202";
          this.markerLabel = this.$t('test_page.indicators.safeometer_test_indicator_urgent').toUpperCase();
          break;
      }
    },
    getQuestionTypeValue(type){
      switch(type){
        case 'warn':
          return 0;
        case 'react':
          return 1;
        case 'urgent':
          return 2;
      }
    },
    getQuestionType(typeValue){
      switch(typeValue){
        case 0:
          return 'warn';
        case 1:
          return 'react';
        case 2:
          return 'urgent';
      }
    },
    loadResult(progress, points){
      this.$router.push(
          {
            name: 'result',
            params: {
              progress: progress,
              points: points,
              markerBackground:  this.markerBackground,
              markerLabel: this.markerLabel,
              higherQuestionType: this.getQuestionType(this.higherQuestionType)
            }
          }
      );
    }
  }
}
</script>

<style>


</style>